import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { ALWAYS_ACTIVE, CUSTOM_TIMEFRAME, DYNAMIC_TIMEFRAME, DEFAULT_TIMEZONE_NAME } from '../constants';
import PostmongerStore from '../utilities/postmonger';

// Component
import MessageConfigurationComponent from '../components/message-configuration';
import MobileApplication from '../components/message-configuration/mobile-application';
import TestSendsButtonHTML from '../components/message-configuration/test-sends/test-sends-button.html';

// Action
import { setActiveMessageTimeframeOption, setDate, setTime, setTimezone } from '../actions/active-message-timeframe';
import { setMessageTriggerEvents, updateEventAttributes } from '../actions/message-trigger';
import { setDynamicTimeframeValue, setDynamicTimeframeUnit } from '../actions/dynamic-timeframe';

// Utilities
import TimezoneUtils from '../utilities/timezone';
import { hasProperty } from '../utilities/helper';
import { getISOStringWithTimezone } from '../utilities/dateTime';

class MessageConfiguration extends React.Component {
	constructor (props) {
		super(props);

		// Initizlie moment with cultue code
		moment.locale(this.props.i18n.getMomentLocale());

		this.renderTestSends = this.renderTestSends.bind(this);

		this.metaData = this.props.dataStore[this.props.sectionKey];
		if (!hasProperty(this.metaData, 'payload')) {
			this.metaData.payload = {};
		}

		// Get user's information using mclib
		props.mcLib.getUserInfo((userInfo) => {
			if (userInfo.timeZone === undefined || (hasProperty(this.metaData, 'timezoneName') && this.metaData.timezoneName.length > 0)) {
				return; // If mclib is not available, skip it
			}

			const timezoneId = Number.isInteger(userInfo.timeZone.timeZoneId) ? userInfo.timeZone.timeZoneId.toString() : userInfo.timeZone.timeZoneId; // Convert Timezone Id to String
			this.userTimezone = TimezoneUtils.getTimezoneItemForComboboxById(timezoneId);

			this.props.setTimezone(null, {
				selection: [this.userTimezone]
			});
		});

		this.initScheduler();
		this.renderSchedulerWithExistingData();
		this.renderDynamicTimeframeWithExistingData();
		this.TestSendsEnabled = !!(PostmongerStore.featureFlag
			&& PostmongerStore.featureFlag.TestSendPreview
			&& PostmongerStore.featureFlag.TestSendPreview.toLowerCase() === 'true');
	}

	initScheduler () {
		if (this.metaData.isCustomTimeframe || this.metaData.isDynamicTimeframe) {
			return;
		}

		this.setDefaultTimeFramePayload();

		this.metaData.isCustomTimeframe = false;
		this.metaData.isDynamicTimeframe = false;
	}

	renderSchedulerWithExistingData () {
		if ((hasProperty(this.metaData.payload, 'startDate') || hasProperty(this.metaData.payload, 'endDate')) && this.metaData.isCustomTimeframe) {
			const timezoneName = hasProperty(this.metaData, 'timezoneName') ? this.metaData.timezoneName : DEFAULT_TIMEZONE_NAME;

			this.props.setActiveMessageTimeframeOption({
				target: {
					value: CUSTOM_TIMEFRAME
				}
			});

			// Render start date
			if (hasProperty(this.metaData.payload, 'startDate')) {
				const startDate = moment(this.metaData.payload.startDate);

				this.props.setDate('start', momentTimezone.tz(startDate, timezoneName).format('L'));
				this.props.setTime('start', momentTimezone.tz(startDate, timezoneName).format('LT'));
			}

			// Render end date
			if (hasProperty(this.metaData.payload, 'endDate')) {
				const endDate = moment(this.metaData.payload.endDate);

				this.props.setDate('end', momentTimezone.tz(endDate, timezoneName).format('L'));
				this.props.setTime('end', momentTimezone.tz(endDate, timezoneName).format('LT'));
			}

			// Render timezone
			if (hasProperty(this.metaData, 'timezoneName')) {
				this.props.setTimezone(null, {
					selection: [TimezoneUtils.getTimezoneItemForComboboxByIanaName(this.metaData.timezoneName)]
				});
			}
		}
	}
	
	renderDynamicTimeframeWithExistingData () {
		if (!hasProperty(this.metaData, 'isDynamicTimeframe') || !this.metaData.isDynamicTimeframe) {
			return;
		}
		
		this.props.setActiveMessageTimeframeOption({
			target: {
				value: DYNAMIC_TIMEFRAME
			}
		});

		this.props.setDynamicTimeframeValue(this.metaData.dynamicTimeframe);
		this.props.setDynamicTimeframeUnit(this.metaData.dynamicTimeframeUnit);
	}

	setDefaultTimeFramePayload () {
		const currentTimezone = this.userTimezone ? this.userTimezone : TimezoneUtils.getTimezoneItemForComboboxByIanaName(DEFAULT_TIMEZONE_NAME); // If MCLib is not available, use default time zone information
		const currentStartDate = moment().toISOString(); // Current time

		// Set startDate to be current time
		this.metaData.payload = {
			...this.metaData.payload,
			startDate: currentStartDate,
			scheduledTzOffset: currentTimezone.utcOffset,
			scheduledTimezone: currentTimezone.value
		};

		delete this.metaData.payload.endDate;

		// Update metaData
		delete this.metaData.endDate;
		delete this.metaData.endTime;
	}

	saveCustomTimeframe () {
		const { i18n } = this.props;

		if (this.props.activeMessageTimeframe === ALWAYS_ACTIVE) {
			// Set startDate to be current time in configurationArguments
			this.setDefaultTimeFramePayload();
			this.metaData.isCustomTimeframe = false;
			this.metaData.isDynamicTimeframe = false;

			return;
		}
		
		if (this.props.activeMessageTimeframe === DYNAMIC_TIMEFRAME) {
			this.saveDynamicTimeframe();
			this.metaData.isCustomTimeframe = false;
			this.metaData.isDynamicTimeframe = true;
			
			return;
		}

		const isStartDateAvaialble = this.props.startDate.length > 0 && this.props.startTime.length > 0;
		const isEndDateAvailable = this.props.endDate.length > 0 && this.props.endTime.length > 0;
		const isTimezoneAvailable = !isEmpty(this.props.selectedTimezone);

		if (!this.metaData.isCustomTimeframe) {
			delete this.metaData.payload.startDate;
		}

		// Update metadata - Metadata can be updated regardless of timezone selection requirement for date and time
		this.metaData.isCustomTimeframe = true;
		this.metaData.isDynamicTimeframe = false;

		if (isStartDateAvaialble) {
			this.metaData.startDate = this.props.startDate;
			this.metaData.startTime = this.props.startTime;
		} else {
			delete this.metaData.startDate;
			delete this.metaData.startTime;
		}

		if (isEndDateAvailable) {
			this.metaData.endDate = this.props.endDate;
			this.metaData.endTime = this.props.endTime;
		} else {
			delete this.metaData.endDate;
			delete this.metaData.endTime;
		}

		if (isTimezoneAvailable) {
			this.metaData.timezoneLabel = i18n.get(this.props.selectedTimezone.label);
			this.metaData.timezoneName = this.props.selectedTimezone.name;
		} else {
			delete this.metaData.timezoneLabel;
			delete this.metaData.timezoneName;
		}

		if (isTimezoneAvailable) { // Timezone must be selected in order to set startDate and endDate data
			if (isStartDateAvaialble) {
				this.metaData.payload = {
					...this.metaData.payload,
					startDate: getISOStringWithTimezone(moment(this.props.startDate, 'L').format('MM/DD/YYYY'), this.props.startTime, this.props.selectedTimezone.name),
					scheduledTzOffset: this.props.selectedTimezone.utcOffset,
					scheduledTimezone: this.props.selectedTimezone.value
				};
			} else {
				// Delete start date data if both time and date is not configured.
				delete this.metaData.payload.startDate;
			}

			if (isEndDateAvailable) {
				this.metaData.payload = {
					...this.metaData.payload,
					endDate: getISOStringWithTimezone(moment(this.props.endDate, 'L').format('MM/DD/YYYY'), this.props.endTime, this.props.selectedTimezone.name),
					scheduledTzOffset: this.props.selectedTimezone.utcOffset,
					scheduledTimezone: this.props.selectedTimezone.value
				};
			} else {
				// Delete end date data if both time and date is not configured.
				delete this.metaData.payload.endDate;
			}
		} else {
			// Delete start and end date data if timezone is not selected.
			delete this.metaData.payload.startDate;
			delete this.metaData.payload.endDate;
			delete this.metaData.payload.scheduledTzOffset;
			delete this.metaData.payload.scheduledTimezone;
		}
		
		// Clean up Dynamic Timeframe data
		delete this.metaData.dynamicTimeframe;
		delete this.metaData.dynamicTimeframeUnit;
	}
	
	saveDynamicTimeframe () {
		// save to metaData
		this.metaData.dynamicTimeframe = this.props.dynamicTimeframe;
		this.metaData.dynamicTimeframeUnit = this.props.dynamicTimeframeUnit;
	}

	updateConfigured () {
		// application data check
		const isRequirementSatisfied = hasProperty(this.metaData.payload, 'application') && hasProperty(this.metaData.payload.application, 'id') && hasProperty(this.metaData.payload.application, 'name') && this.metaData.payload.application.id.length > 0 && this.metaData.payload.application.name.length > 0;

		if (isRequirementSatisfied) {
			this.renderTestSends();
			this.props.xt.enableSpoke('advancedOptions');
		}
	}

	renderMobileApplication () {
		const container = document.querySelector('#mobile-application-container');
		const configs = {
			i18n: this.props.i18n,
			store: this.props.dataStore,
			sectionKey: this.props.sectionKey,
			xt: this.props.xt,
			applications: this.props.mobileApps || [],
			isFetching: this.isFetching,
			hideLoadMask: this.props.hideLoadMask,
			showLoadMask: this.props.showLoadMask,
			setMessageTriggerEvents: this.props.setMessageTriggerEvents,
			updateEventAttributes: this.props.updateEventAttributes,
			renderTestSends: this.renderTestSends
		};

		MobileApplication(container, configs);
	}

	renderTestSends () {
		if (!this.TestSendsEnabled) {
			return;
		}

		const container = document.querySelector('#test-sends-button-container');
		container.innerHTML = TestSendsButtonHTML.toString().replace('{{test_send_btn_string}}', this.props.i18n.get('test_send_button'));
		const { dataStore, sectionKey } = this.props;

		const isRequirementSatisfied = hasProperty(this.metaData.payload, 'application') && hasProperty(this.metaData.payload.application, 'id') && hasProperty(this.metaData.payload.application, 'name') && this.metaData.payload.application.id.length > 0 && this.metaData.payload.application.name.length > 0;

		const msgValid = this.props.dataStore.messageDefinition
			&& dataStore.messageDefinition.content
			&& dataStore.messageDefinition.content.name
			&& dataStore.messageDefinition.content.id;

		if (isRequirementSatisfied && msgValid) {
			const btnWrapper = document.querySelector('.test-sends-button');
			if (btnWrapper) {
				btnWrapper.classList.remove('hidden');
				const btn = document.querySelector('#test-send-btn');
				btn.addEventListener('click', () => {
					this.props.resize('scm-full');
					const msgPayload = dataStore.messageDefinition.content;
					this.props.onTestSendsButtonClick(dataStore[sectionKey].payload.application, msgPayload, this.props.i18n);
				});
			}
		}
	}

	componentDidUpdate () {
		if ((this.isFetching === true) && (this.props.mobileApps) && (this.props.mobileApps.length > 0)) {
			this.isFetching = false;

			this.renderMobileApplication();
		}

		this.saveCustomTimeframe();
		this.saveDynamicTimeframe();
		this.updateConfigured();

		// Print out payload for debugging purpose
		console.log(this.props.dataStore);
	}

	componentDidMount () {
		this.isFetching = true;

		if ((this.props.mobileApps) && (this.props.mobileApps.length > 0)) {
			this.isFetching = false;
		}

		this.renderTestSends();
		this.renderMobileApplication();
	}

	render () {
		return (
			<MessageConfigurationComponent
				i18n={this.props.i18n}
				activeMessageTimeframe={this.props.activeMessageTimeframe}
				onClickActiveMessageTimeframe={this.props.setActiveMessageTimeframeOption}
				onSelectDate={this.props.setDate}
				startDate={this.props.startDate}
				startTime={this.props.startTime}
				onSelectTime={this.props.setTime}
				endDate={this.props.endDate}
				endTime={this.props.endTime}
				selectedTimezone={this.props.selectedTimezone}
				onSelectTimezone={this.props.setTimezone}
				menuValidationState={this.props.menuValidationState}
			/>
		);
	}
}

MessageConfiguration.propTypes = {
	mcLib: PropTypes.object.isRequired,
	i18n: PropTypes.object.isRequired,
	activity: PropTypes.object.isRequired,
	sdk: PropTypes.object.isRequired,
	sectionKey: PropTypes.string.isRequired,
	mobileApps: PropTypes.array.isRequired,
	activeMessageTimeframe: PropTypes.string.isRequired,
	setActiveMessageTimeframeOption: PropTypes.func.isRequired,
	setDate: PropTypes.func.isRequired,
	setTime: PropTypes.func.isRequired,
	setTimezone: PropTypes.func.isRequired,
	selectedTimezone: PropTypes.object.isRequired,
	startDate: PropTypes.string,
	startTime: PropTypes.string,
	endDate: PropTypes.string,
	endTime: PropTypes.string,
	xt: PropTypes.object.isRequired,
	dataStore: PropTypes.object.isRequired,
	hideLoadMask: PropTypes.func.isRequired,
	showLoadMask: PropTypes.func.isRequired,
	setMessageTriggerEvents: PropTypes.func.isRequired,
	updateEventAttributes: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	mobileApps: state.MobileAppSelection.mobileApps,
	activeMessageTimeframe: state.ActiveMessageTimeframe.activeMessageTimeframe,
	selectedTimezone: state.ActiveMessageTimeframe.selectedTimezone,
	startDate: state.ActiveMessageTimeframe.startDate,
	startTime: state.ActiveMessageTimeframe.startTime,
	endDate: state.ActiveMessageTimeframe.endDate,
	endTime: state.ActiveMessageTimeframe.endTime,
	dynamicTimeframe: state.DynamicTimeframe.dynamicTimeframe,
	dynamicTimeframeUnit: state.DynamicTimeframe.dynamicTimeframeUnit
});

const mapDispatchToProps = (dispatch) => ({
	setActiveMessageTimeframeOption: (event) => {
		dispatch(setActiveMessageTimeframeOption(event.target.value));
	},
	setDate: (type, date) => {
		dispatch(setDate({
			type,
			date
		}));
	},
	setTime: (type, time) => {
		dispatch(setTime({
			type,
			time
		}));
	},
	setTimezone: (event, data) => {
		if (isEmpty(data.selection)) {
			return; // Disable combobox selection toggle
		}

		dispatch(setTimezone(data.selection));
	},
	setMessageTriggerEvents: (events) => {
		dispatch(setMessageTriggerEvents(events));
	},
	updateEventAttributes: (attributes) => {
		dispatch(updateEventAttributes(attributes));
	},
	setDynamicTimeframeValue: (value) => {
		dispatch(setDynamicTimeframeValue(value));
	},
	setDynamicTimeframeUnit: (value) => {
		dispatch(setDynamicTimeframeUnit(value));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageConfiguration);
